<template>
  <!-- 学徒培训 -->
  <div class="container">
    <a-page-header
      :title="title"
      :sub-title="subTitle"
      @back="() => $router.go(-1)"
    >
      <template slot="extra" v-if="info">
        <a-button :disabled="info.train > 0" @click="changeStatus">
          培训完成
        </a-button>
        <a-button
          :disabled="info.apply > 0"
          @click="
            $jump({
              path: '/apprenticeTurn',
              query: {
                id: info.id
              }
            })
          "
        >
          转编师傅
        </a-button>
      </template>
    </a-page-header>
    <div class="main-content">
      <div class="content-item" v-if="info">
        <div class="content-item-header">
          <div class="title">培训信息</div>
        </div>

        <div class="content-sub-title">
          <span>概括</span>
          <a-button type="link" @click="openModal">
            编辑讲师/带训
          </a-button>
        </div>

        <div class="ul">
          <div class="li">
            <span>理论讲师：</span>
            <span v-if="info.lectorName">{{ info.lectorName }}</span>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>实操讲师：</span>
            <span v-if="info.trainName">{{ info.trainName }}</span>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>带训师傅：</span>
            <span v-if="info.visitiservicer">{{ info.visitiservicer }}</span>
            <span v-else>-</span>
          </div>
        </div>

        <a-divider />

        <div class="content-sub-title">
          <span>培训成绩</span>
        </div>
      </div>
    </div>

    <a-modal
      v-model="modalVisible"
      :title="title + ' - 讲师/带训'"
      :footer="false"
    >
      <a-form-model
        ref="modalRuleForm"
        :model="modalForm"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label="理论讲师">
          <a-select v-model="modalForm.lectorId" placeholder="请选择理论讲师">
            <a-select-option
              v-for="item in lectors"
              :key="item.id"
              :value="item.id"
            >
              {{ item.realName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="实操讲师">
          <a-select v-model="modalForm.trainingId" placeholder="请选择实操讲师">
            <a-select-option
              v-for="item in trainings"
              :key="item.id"
              :value="item.id"
            >
              {{ item.realName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="带训师傅">
          <a-select
            v-model="modalForm.visitiservicerId"
            placeholder="请选择带训师傅"
          >
            <a-select-option
              v-for="item in visitisers"
              :key="item.id"
              :value="item.id"
            >
              {{ item.realName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            class="submit-btn"
            type="primary"
            :loading="modalLoading"
            @click="handleSubmit"
          >
            保存
          </a-button>
          <a-button class="cancel-btn" @click="handleCancel">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
let apprenticeId = ''
export default {
  data() {
    return {
      title: '',
      subTitle: '',
      info: null,
      modalVisible: false,
      modalLoading: false,
      modalForm: {
        id: undefined,
        lectorId: undefined,
        trainingId: undefined,
        visitiservicerId: undefined
      },
      lectors: [], // 理论讲师
      trainings: [], // 实操讲师
      visitisers: [] // 带训师傅
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    apprenticeId = query.id
    this.modalForm.id = apprenticeId
    this.getApprenticeTraining()
  },
  methods: {
    getApprenticeTraining() {
      // 获取学徒培训信息
      const data = {
        id: apprenticeId
      }
      this.$axios.getApprenticeTraining(data).then((res) => {
        const d = res.data.data
        this.info = d
        this.subTitle = `${d.realName}（${d.studentNumber}）`
      })
    },
    openModal() {
      // 编辑讲师/带训
      this.modalVisible = true
      this.getLectors()
      this.getTrainings()
      this.getVisitisers()
    },
    getLectors() {
      // 获取学徒理论讲师列表
      this.$axios.getLectors().then((res) => {
        this.lectors = res.data.data
      })
    },
    getTrainings() {
      // 获取实操讲师列表
      this.$axios.getTrainings().then((res) => {
        this.trainings = res.data.data
      })
    },
    getVisitisers() {
      // 获取带训师傅列表
      this.$axios.getVisitisers().then((res) => {
        this.visitisers = res.data.data
      })
    },
    handleCancel() {
      // 取消编辑讲师/带训
      this.modalVisible = false
      this.modalLoading = false
      this.$refs.modalRuleForm.resetFields()
    },
    handleSubmit() {
      // 提交编辑讲师/带训
      this.$refs.modalRuleForm.validate((valid) => {
        if (valid) {
          this.modalLoading = true
          this.$axios.changeApprenticeMaster(this.modalForm).then(() => {
            this.$message.success('操作成功')
            this.handleCancel()
            this.getApprenticeTraining()
          })
        }
      })
    },
    changeStatus() {
      // 培训完成
      this.$confirm({
        title: '确定培训完成？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: apprenticeId
          }
          this.$axios.apprenticeTrainFinish(data).then(() => {
            this.$message.success('操作成功')
          })
        }
      })
    }
  }
}
</script>
<style src="../../assets/css/info-page.css" scoped />
<style scoped>
.content-item-header {
  margin-bottom: 24px;
}

.submit-btn {
  margin: 0 12px 0 7em;
}
</style>
